import React from 'react'

const ID = function () {
  return Math.random().toString(36).substr(2, 9)
}

function RaceItem(props) {
  const { item } = props.lineItem
  const { selection } = props.lineItem
  let first_options; let
    second_options = null

  if (item.attribute_1_options.length > 0) {
    first_options = (
      <span>
        {item.attribute_1_title}
:
        <select name="items[][option_1]" defaultValue={selection.option_1} style={{ width: 'auto' }} className="form-control">
          <option />
          { item.attribute_1_options.map((option, i) => <option key={i} value={option}>{option}</option>) }
        </select>
      </span>
    )
  }

  if (item.attribute_2_options.length > 0) {
    second_options = (
      <span>
        {item.attribute_2_title}
:
        <select name="items[][option_2]" defaultValue={selection.option_2} style={{ width: 'auto' }} className="form-control">
          <option />
          { item.attribute_2_options.map((option, i) => <option key={i} value={option}>{option}</option>) }
        </select>
      </span>
    )
  }

  const quantity = [...Array(item.max_quantity).keys()].map((i) => <option key={i} value={i + 1}>{i + 1}</option>)

  return (
    <tr key={item.ui_id}>
      <td>
        <input type="hidden" name="items[][id]" value={item.id} />
        <input type="hidden" name="items[][item_option]" value={item.title} />
        {item.title}
        {' '}
- $
        {item.price_cents / 100.00}
      </td>
      <td>{first_options}</td>
      <td>{second_options}</td>
      <td>
        <select name="items[][quantity]" defaultValue={selection.quantity} style={{ width: 'auto' }} className="form-control">
          { quantity }
        </select>
      </td>
      <td><button className="button" onClick={(e) => props.remove(e, props.lineItem.ui_id)} className="btn btn-danger">Delete</button></td>
    </tr>
  )
}

function RaceItemsTable(props) {
  if (props.lineItems.length == 0) {
    return null
  }

  return (
    <table>
      <thead>
        <tr>
          <th>Item</th>
          <th />
          <th />
          <th>Quantity</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {props.lineItems.map((item) => <RaceItem key={item.id} lineItem={item} remove={props.remove} />)}
      </tbody>
    </table>
  )
}

class RaceItems extends React.Component {
  constructor(props) {
    super(props)

    const lineItems = props.selections.map((selection) => {
      const item = props.items.find((item) => item.title === selection.item_option)

      return { item, selection, ui_id: ID() }
    })

    this.state = {
      currentItem: '?',
      lineItems,
    }

    this.selectItem = this.selectItem.bind(this)
    this.handleAdd = this.handleAdd.bind(this)
    this.remove = this.remove.bind(this)
  }

  remove(event, id) {
    event.preventDefault()
    event.stopPropagation()

    this.setState((prevState) => ({
      lineItems: prevState.lineItems.filter((lineItem) => {
        if (lineItem.ui_id == id) {
          return false
        }
        return true
      }),
    }))
  }

  selectItem(event) {
    this.setState({ currentItem: event.target.value })
  }

  handleAdd(event) {
    const value = this.state.currentItem

    if (value === '?') {
      alert('Please select an item.')
    }

    this.setState((prevState) => {
      const item = this.props.items.find((item) => item.id == value)

      return { lineItems: prevState.lineItems.concat([{ item: { ...item }, selection: {}, ui_id: ID() }]) }
    })
  }

  render() {
    return (
      <div>
        <div className="row mb-3">
          <select onChange={this.selectItem} className="form-control col-12 col-md-9">
            <option value="?" />
            { this.props.items.map((item) => <option key={item.id} value={item.id}>{item.title}</option>)}
          </select>
          <a onClick={this.handleAdd} className="btn btn-primary col-12 col-md-3">
            <i className="icon-plus" />
Add Item
          </a>
        </div>
        <RaceItemsTable lineItems={this.state.lineItems} remove={this.remove} />
      </div>
    )
  }
}

export default RaceItems
