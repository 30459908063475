import React from 'react'

function Team(props) {
  const { team } = props

  const bags = (
    <ul>
      { team.packet_bags.map((bag) => <li><a href={`bag/${bag.id}`}>{bag.name}</a></li>) }
    </ul>
  )

  return (
    <div>
      <h2>
        {team.name}
        (
        {team.unbagged_runners_count}
        )
      </h2>
      { bags }
      { team.unbagged_runners_count > 0 && <a href={`bag/new?team_id=${team.id}`}>Create new Bag</a> }
    </div>
  )
}

class BagList extends React.Component {
  constructor(props) {
    super(props)

    this.state = { search: '' }

    this.filter = this.filter.bind(this)
  }

  filter(event) {
    const { target } = event
    const { value } = target

    this.setState({ search: value.toLowerCase() })
  }

  render() {
    const teams = this.props.teams.filter((team) => (team.name.toLowerCase().includes(this.state.search)))
      .sort((a, b) => a.unbagged_runners_count > b.unbagged_runners_count)
      .reverse()

    const teamComponents = teams.map((team) => <Team team={team} key={team.id} />)

    return (
      <div>
        <input type="text" onChange={this.filter} placeholder="Search..." />
        { teamComponents }
      </div>
    )
  }
}

export default BagList
