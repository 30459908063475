import React from 'react'
import $ from 'jquery'

function SearchBox(props) {
  return (
    <div className="search noprint col1">
      <div className="heading">
        Bag Pick Up
      </div>
      <div className="titles">
        <div className="team">
          Team Name
        </div>
      </div>
      <div className="fields">
        <div className="team">
          <input type="text" onChange={props.filter} placeholder="Search..." />
        </div>
      </div>
    </div>
  )
}

class TeamPickup extends React.Component {
  constructor(props) {
    super(props)

    this.state = { search: '', bags: props.bags }

    this.filter = this.filter.bind(this)
    this.pickup = this.pickup.bind(this)
  }

  filter(event) {
    const { value } = event.target

    this.setState((prevState) => (
      { ...prevState, search: value.toLowerCase() }
    ))
  }

  pickup(event, bagId) {
    const bag = this.state.bags.find((e) => e.bag_id === bagId)
    const url = bag._links.pickup.href

    $.ajax({
      url,
      type: 'PUT',
      headers: {
        'X-CSRF-Token': window.auth_token,
      },
      success: (response) => {
        this.setState((prevState) => {
          const newArray = prevState.bags.filter((elem) => elem.bag_id !== bagId)

          newArray.push(response)

          return { ...prevState, bags: newArray }
        })
      },
    })
  }

  render() {
    const bags = this.state.bags
      .filter((team) => (team.name.toLowerCase().includes(this.state.search)))
      .sort((a, b) => a.name > b.name)

    const rows = bags.map((bag) => (
      <tr key={bag.bag_id}>
        <td>
          { !bag.timestamp
            && <button onClick={(e) => this.pickup(e, bag.bag_id)} className="button btn-sm">Pickup</button>}
        </td>
        <td>{bag.team_name}</td>
        <td>{bag.name}</td>
        <td>{bag.timestamp}</td>
      </tr>
    ))

    return (
      <div>
        <SearchBox filter={this.filter} />

        <br />

        <table id="runner_list" className="print">
          <thead>
            <tr>
              <th />
              <th>Team Name</th>
              <th>Bag Name</th>
              <th>Timestamp</th>
            </tr>
          </thead>
          <tbody id="offTblBdy">
            { rows }
          </tbody>
        </table>
      </div>
    )
  }
}

export default TeamPickup
