import React from 'react'
import ReactDOM from 'react-dom'
import CustomFieldEditor from './custom_field_editor'
import BagList from './bag_list'
import RaceItems from './race_items'
import TeamPickup from './team_pickup'
import {} from 'jquery'
import {} from 'jquery-ujs'
import '@fortawesome/fontawesome-free/js/all'
import 'bootstrap'

import './backend_base'
import './admin_race_items'
import './popup'

document.addEventListener('DOMContentLoaded', () => {
  const node = document.querySelector('#custom-field-editor')
  const props = JSON.parse(node.dataset.reactProps)

  if (node) {
    const element = React.createElement(CustomFieldEditor, props)
    ReactDOM.render(element, node)
  }
})

document.addEventListener('DOMContentLoaded', () => {
  const node = document.querySelector('#bag-list')
  const props = JSON.parse(node.dataset.reactProps)

  if (node) {
    const element = React.createElement(BagList, props)
    ReactDOM.render(element, node)
  }
})

document.addEventListener('DOMContentLoaded', () => {
  const node = document.querySelector('#race-items')
  const props = JSON.parse(node.dataset.reactProps)

  if (node) {
    const element = React.createElement(RaceItems, props)
    ReactDOM.render(element, node)
  }
})

document.addEventListener('DOMContentLoaded', () => {
  const node = document.querySelector('#team-pickup')
  const props = JSON.parse(node.dataset.reactProps)

  if (node) {
    const element = React.createElement(TeamPickup, props)
    ReactDOM.render(element, node)
  }
})
import * as bootstrap from "bootstrap"
