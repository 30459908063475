import $ from 'jquery'

$(document).ready(() => {
  $('#noteModal').on('shown.bs.modal', function () {
      $('#noteModal textarea').focus()
  })

  $('a.note_button').each(function () {
    const url = $(this).data('url')
    $(this).click((e) => {
      $('#noteModal .modal-body').load(url)
      $('#noteModal').modal('show')
      e.preventDefault()
    })
  })
})

const jQueryProp = {
  value: $,
  configurable: false,
  writable: false,
}

Object.defineProperties(window, { $: jQueryProp })
