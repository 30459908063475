import $ from 'jquery'

$(() => {
  const addAnotherRaceItem = (i) => {
    const input = $(`<input type='text' name='race_item[attribute_${i}_options][]' id='race_item_attribute=${i}_options_'>`)

    const ol = $(`#attribute_${i}_options`)
    ol.append(input)
    ol.append($('<br />'))

    input.focus()
  }

  $('#race_item_add_option_1').click(() => {
    addAnotherRaceItem(1)
  })

  $('#race_item_add_option_2').click(() => {
    addAnotherRaceItem(2)
  })
})
