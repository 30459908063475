import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

const ID = function () {
  return `_${Math.random().toString(36).substr(2, 9)}`
}

function CustomField(props) {
  return (
    <fieldset className="well">
      <div className="control-group">
        <label className="control-label" htmlFor="custom_field_name">Name</label>
        <div className="controls"><input type="text" name="custom_fields[][name]" defaultValue={props.custom_field.name} /></div>
      </div>

      <div className="control-group">
        <label className="control-label" htmlFor="custom_field_include">Include</label>
        <div className="controls">
          <input type="checkbox" name="custom_fields[][include]" defaultChecked={props.custom_field.include} />
        </div>
      </div>

      <div className="control-group">
        <label className="control-label" htmlFor="custom_field_required">Required</label>
        <div className="controls">
          <input type="checkbox" name="custom_fields[][required]" defaultChecked={props.custom_field.required} />
        </div>
      </div>

      <div className="control-group">
        <label className="control-label" htmlFor="custom_field_description">Description</label>
        <div className="controls"><input type="text" name="custom_fields[][description]" defaultValue={props.custom_field.description} /></div>
      </div>

      <div className="control-group">
        <label className="control-label" htmlFor="custom_field_validation">Validation (Regex)</label>
        <div className="controls">
          <div className="input-prepend input-append">
            <span className="add-on">^</span>
            <input type="text" name="custom_fields[][validation]" defaultValue={props.custom_field.validation} />
            <span className="add-on">$</span>
          </div>
        </div>
      </div>

      <div className="control-group">
        <label className="control-label" htmlFor="custom_field_sym">Sym (lowercase, letters only)</label>
        <div className="controls"><input type="text" pattern="[A-Za-z_]*" name="custom_fields[][sym]" defaultValue={props.custom_field.sym} /></div>
      </div>

      <div className="control-group">
        <label className="control-label" htmlFor="custom_field_description">Order</label>
        <div className="controls"><input type="text" pattern="\d*" name="custom_fields[][ord]" defaultValue={props.custom_field.ord} /></div>
      </div>

      <button className="button" onClick={(e) => props.remove(e, props.custom_field.id)}>Remove</button>
    </fieldset>
  )
}

class CustomFieldEditor extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      custom_fields: props.custom_fields.map((cf) => {
        cf.id = ID(); return cf
      }),
    }

    this.remove = this.remove.bind(this)
  }

  remove(e, id) {
    e.preventDefault()

    this.setState((prevState) => ({
      custom_fields: prevState.custom_fields.filter((custom_field) => {
        if (custom_field.id == id) {
          return false
        }
        return true
      }),
    }))
  }

  add(e) {
    e.preventDefault()

    this.setState((prevState) => ({
      custom_fields: prevState.custom_fields.append({ id: ID() }),
    }))
  }

  render() {
    const fields = this.state.custom_fields.map((cf) => <CustomField key={cf.id} custom_field={cf} remove={this.remove} />)

    return (
      <div>
        {fields}
        <button className="button" onClick={(e) => { this.add(e) }}>Add</button>
      </div>
    )
  }
}

export default CustomFieldEditor
